<template>
  <div>
    <el-container>
    <el-header :height="height">
      <h3>Travian 发兵文件编辑器</h3>
      <el-card>
        <div slot="header" class="clearfix">
          <span>控制面板</span>
          <el-button type="text" style="float: right; padding: 3px 0px;" @click.native.prevent="downloadJson()">导出</el-button>
          <el-button type="text" style="float: right; padding: 3px 20px 0px;" @click.native.prevent="selectFile()">导入</el-button>
          <el-upload
            style="display: none;"
            class="upload-demo"
            ref="upload"
            :on-change="handleFile"
            action="https://jsonplaceholder.typicode.com/posts/"
            :auto-upload="false">
            <el-button slot="trigger" id="selectFile"></el-button>
          </el-upload>
        </div>
        <el-row>
          <el-col :span="2" style="padding-right: 10px;">
            <span>代理配置</span>
          </el-col>
          <el-col :span="12">
            <div style="display: flex;">
              <el-checkbox style="line-height: 40px; padding-right: 20px;" v-model="dispatch.useProxy">使用代理</el-checkbox>
              <el-input
                v-model="dispatch.proxy"
                placeholder="代理信息(eg: username:password@127.0.0.1:4792)"
                prefix-icon="el-icon-s-promotion">
              </el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2" style="padding-right: 10px;">
            <span>基本信息</span>
          </el-col>
          <el-col :span="8">
            <el-input
              v-model="dispatch.serverUrl"
              placeholder="服务器地址"
              prefix-icon="el-icon-link">
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              v-model="dispatch.username"
              placeholder="用户名"
              prefix-icon="el-icon-user">
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              v-model="dispatch.password"
              placeholder="密码"
              show-password>
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-input
              v-model="dispatch.preparing.villageId"
              placeholder="出兵村ID"
              prefix-icon="el-icon-s-home">
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-select
              v-model="dispatch.tribeId"
              placeholder="种族">
              <el-option
                v-for="item in tribeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2" style="padding-right: 10px;">
            <span>出兵信息</span>
          </el-col>
          <el-col :span="2" v-for="count in 10" :key="count">
            <el-input
              v-model="dispatch.preparing.troops[count-1]"
              placeholder="0">
              <template slot="prefix">
                <img :class="'unit u' + (count + dispatch.tribeId * 10)" style="margin-top: 12px;" src="../assets/imgs/x.gif">
              </template>
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-input
              v-model="dispatch.preparing.troops[10]"
              placeholder="0">
              <template slot="prefix">
                <img class="unit uhero" style="margin-top: 12px;" src="../assets/imgs/x.gif">
              </template>
            </el-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2" :offset="2">
            <el-input
              placeholder="目标X"
              v-model="dispatch.preparing.target.x"
              prefix-icon="el-icon-map-location">
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-input
              placeholder="目标Y"
              v-model="dispatch.preparing.target.y"
              prefix-icon="el-icon-map-location">
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-checkbox border v-model="dispatch.preparing.useShip">乘船</el-checkbox>
          </el-col>
          <el-col :span="2">
            <el-select
              v-model="dispatch.preparing.dispatchType"
              placeholder="方式">
              <template slot="prefix">
                <i class="el-icon-setting el-input__icon"></i>
              </template>
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-input
              v-model="dispatch.preparing.arrivalTime"
              placeholder="抵达时间(eg: 12:00:00)"
              prefix-icon="el-icon-time">
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-select
              v-model="dispatch.preparing.catapult1"
              placeholder="投石目标">
              <template slot="prefix">
                <i class="el-icon-aim el-input__icon"></i>
              </template>
              <el-option
                v-for="item in targetOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select
              v-model="dispatch.preparing.catapult2"
              placeholder="投石目标">
              <template slot="prefix">
                <i class="el-icon-aim el-input__icon"></i>
              </template>
              <el-option
                v-for="item in targetOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click.native.prevent="addRow">加入出兵</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2" style="padding-right: 10px;">
            <span>佯攻信息</span>
          </el-col>
          <el-col :span="2" v-for="count in 8" :key="count">
            <el-input
              v-model="dispatch.preparing.feintTroops[count-1]"
              placeholder="0">
              <template slot="prefix">
                <img :class="'unit u' + (count + dispatch.tribeId * 10)" style="margin-top: 12px;" src="../assets/imgs/x.gif">
              </template>
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-select
              v-model="dispatch.preparing.feintMulti"
              placeholder="波数">
              <el-option
                v-for="item in multiOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" :offset="2">
            <el-select
              v-model="dispatch.preparing.currentSelectFeintTarget"
              placeholder="佯攻坐标">
              <template slot="prefix">
                <i class="el-icon-place el-input__icon"></i>
              </template>
              <el-option
                v-for="target in dispatch.preparing.feintTargets"
                :key="target.x*1000+target.y"
                :label="target.x + '|' + target.y"
                :value="target.x*1000+target.y">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button type="warning" @click.native.prevent="deleteFeintTarget">删除坐标</el-button>
          </el-col>
          <el-col :span="2">
            <el-input
              placeholder=" 目标X"
              v-model="dispatch.preparing.feintTarget.x"
              prefix-icon="el-icon-map-location">
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-input
              placeholder="目标Y"
              v-model="dispatch.preparing.feintTarget.y"
              prefix-icon="el-icon-map-location">
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-checkbox border v-model="dispatch.preparing.feintUseShip">乘船</el-checkbox>
          </el-col>
          <el-col :span="2">
            <el-button type="info" @click.native.prevent="addFeintTarget">添加坐标</el-button>
          </el-col>
          <el-col :span="4">
            <el-input
              v-model="dispatch.preparing.feintArrivalTime"
              placeholder="抵达时间(eg: 12:00:00.1)"
              prefix-icon="el-icon-time">
            </el-input>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" @click.native.prevent="addFeint">加入佯攻</el-button>
          </el-col>
        </el-row>
        <el-tag type="info" closable v-if="showInfo" @close="handleCloseInfo">提示：罗马佯攻推荐用侦查；罗马联攻手请家里自备一些侦查来做佯攻</el-tag>
      </el-card>
    </el-header>
    <el-main>
      <el-table
        :data="dispatch.attackTimerTasks"
        stripe
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          prop="villageId"
          label="出兵村ID">
        </el-table-column>
        <el-table-column
          label="目标坐标">
          <template slot-scope="scope">
            {{scope.row.target.x}}|{{scope.row.target.y}}
          </template>
        </el-table-column>
        <el-table-column
          label="乘船">
          <template slot-scope="scope">
            {{scope.row.useShip ? "是" : "否"}}
          </template>
        </el-table-column>
        <el-table-column
          prop="arrivalTime"
          label="抵达时间">
        </el-table-column>
        <el-table-column
          min-width='240'
          label="军队概况">
          <template slot-scope="scope">
            <span v-for="count in 10" :key="count">
              <span v-if="scope.row.troops[count-1] > 0"  style="padding-right: 20px;">
                <img :class="'unit u'+(count + dispatch.tribeId * 10)" src='../assets/imgs/x.gif'> {{scope.row.troops[count-1]}}
              </span>
            </span>
            <span v-if="scope.row.troops[10] > 0"  style="padding-right: 20px;">
              <img  class='unit uhero' src='../assets/imgs/x.gif'> {{scope.row.troops[10]}}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dispatchType"
          label="方式">
        </el-table-column>
        <el-table-column
          prop="catapult1"
          label="投石目标1">
        </el-table-column>
        <el-table-column
          prop="catapult2"
          label="投石目标2">
        </el-table-column>
        <el-table-column
              fixed="right"
              label="操作"
              width="120">
              <template slot-scope="scope">
                <el-button
                  @click.native.prevent="deleteRow(scope.$index)"
                  type="text"
                  size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
      </el-table>
    </el-main>
    <el-dialog
      title="加入失败"
      :visible.sync="dialogVisible"
      width="30%">
      <span>{{dialogMessage}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    </el-container>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'

export default {
  methods: {
    deleteRow (index) {
      this.$data.dispatch.attackTimerTasks.splice(index, 1)
    },
    dialog (message) {
      this.$data.dialogMessage = message
      this.$data.dialogVisible = true
    },
    handleCloseInfo () {
      this.$data.showInfo = false
      this.$data.height = '510px'
    },
    addRow () {
      var preparing = JSON.parse(JSON.stringify(this.$data.dispatch.preparing))
      var totalTroops = 0
      for (var i = 0; i < preparing.troops.length; i++) {
        if (!preparing.troops[i]) preparing.troops[i] = 0
        totalTroops += parseInt(preparing.troops[i])
      }
      if (totalTroops < 1) {
        this.dialog('请输入部队数量！')
        return
      }
      if (!preparing.villageId) {
        this.dialog('请输入出兵村ID！')
        return
      }
      if (!preparing.arrivalTime) {
        this.dialog('请输入抵达时间！')
        return
      }
      if (!preparing.dispatchType) {
        this.dialog('请选择出兵方式！')
        return
      }
      if (!preparing.target.x || !preparing.target.y) {
        this.dialog('请输入目标！')
        return
      }
      var task = {}
      task.troops = preparing.troops
      task.target = preparing.target
      task.dispatchType = preparing.dispatchType
      task.arrivalTime = preparing.arrivalTime
      task.useShip = preparing.useShip
      if (preparing.catapult1) task.catapult1 = preparing.catapult1
      else task.catapult1 = '99'
      if (preparing.catapult2) task.catapult2 = preparing.catapult2
      else task.catapult2 = '0'
      task.villageId = preparing.villageId
      console.log(task)
      this.$data.dispatch.attackTimerTasks.push(task)
    },
    addFeint () {
      var preparing = JSON.parse(JSON.stringify(this.$data.dispatch.preparing))
      for (var i = 0; i < preparing.feintTroops.length; i++) {
        if (!preparing.feintTroops[i]) preparing.feintTroops[i] = 0
      }
      var multi = preparing.feintMulti
      for (i = 0; i < preparing.feintTargets.length; i++) {
        for (var j = 0; j < multi; j++) {
          var task = {}
          task.troops = preparing.feintTroops
          task.target = preparing.feintTargets[i]
          task.dispatchType = 3
          task.arrivalTime = preparing.feintArrivalTime
          task.useShip = preparing.feintUseShip
          task.catapult2 = 99
          task.catapult1 = 99
          task.villageId = preparing.villageId
          this.$data.dispatch.attackTimerTasks.push(task)
        }
      }
    },
    addFeintTarget () {
      var feintTarget = JSON.parse(JSON.stringify(this.$data.dispatch.preparing.feintTarget))
      var canAdd = true
      this.$data.dispatch.preparing.feintTargets.forEach(target => {
        if (target.x * 1000 + target.y === feintTarget.x * 1000 + feintTarget.y) {
          canAdd = false
        }
      })
      if (canAdd) {
        this.$data.dispatch.preparing.feintTargets.push(feintTarget)
      }
    },
    deleteFeintTarget () {
      var ondel = this.$data.dispatch.preparing.currentSelectFeintTarget
      this.$data.dispatch.preparing.feintTargets = this.$data.dispatch.preparing.feintTargets.filter(target => target.x * 1000 + target.y !== ondel)
      this.$data.dispatch.preparing.currentSelectFeintTarget = null
    },
    selectFile () {
      document.getElementById('selectFile').click()
    },
    downloadJson () {
      var jsonStr = JSON.stringify(this.$data.dispatch)
      const strData = new Blob([jsonStr], { type: 'text/plain;charset=utf-8' })
      saveAs(strData, 'dispatch.json')
    },
    handleFile (file, fileList) {
      var rawFile = file.raw
      const reader = new FileReader()
      reader.readAsText(rawFile, 'utf8')
      reader.onload = () => {
        var json = JSON.parse(reader.result)
        this.$data.dispatch = json
      }
    }
  },
  created: function () {
    this.$emit('setShowNavi', false)
  },
  destroyed () {
    this.$emit('setShowNavi', true)
  },
  data () {
    return {
      dialogVisible: false,
      dialogMessage: '默认消息',
      height: '540px',
      showInfo: true,
      tribeOptions: [{
        value: '0',
        label: '罗马人'
      }, {
        value: '1',
        label: '日耳曼人'
      }, {
        value: '2',
        label: '高卢人'
      }, {
        value: '5',
        label: '埃及人'
      }, {
        value: '6',
        label: '匈奴人'
      }, {
        value: '7',
        label: '斯巴达人'
      }],
      typeOptions: [{
        value: '3',
        label: '3.攻击'
      }, {
        value: '4',
        label: '4.掠夺'
      }, {
        value: '5',
        label: '5.增援'
      }],
      multiOptions: [{
        value: '1',
        label: 'x1'
      }, {
        value: '2',
        label: 'x2'
      }, {
        value: '3',
        label: 'x3'
      }, {
        value: '4',
        label: 'x4'
      }],
      targetOptions: [{
        value: '99',
        label: '99.随机目标'
      },
      {
        value: '0',
        label: '0.空地'
      },
      {
        value: '1',
        label: '1.伐木场'
      }, {
        value: '2',
        label: '2.泥坑'
      }, {
        value: '3',
        label: '3.铁矿场'
      }, {
        value: '4',
        label: '4.农场'
      }, {
        value: '5',
        label: '5.锯木厂'
      }, {
        value: '6',
        label: '6.砖厂'
      }, {
        value: '7',
        label: '7.钢铁铸造厂'
      }, {
        value: '8',
        label: '8.面粉厂'
      }, {
        value: '9',
        label: '9.面包店'
      }, {
        value: '10',
        label: '10.仓库'
      }, {
        value: '11',
        label: '11.谷仓'
      }, {
        value: '13',
        label: '13.盔甲厂'
      }, {
        value: '14',
        label: '14.竞技场'
      }, {
        value: '15',
        label: '15.村庄大楼'
      }, {
        value: '16',
        label: '16.集结点'
      }, {
        value: '17',
        label: '17.市场'
      }, {
        value: '18',
        label: '18.大使馆'
      }, {
        value: '19',
        label: '19.兵营'
      }, {
        value: '20',
        label: '20.马厩'
      }, {
        value: '21',
        label: '21.工场'
      }, {
        value: '22',
        label: '22.研究院'
      }, {
        value: '24',
        label: '24.城镇厅'
      }, {
        value: '25',
        label: '25.行宫'
      }, {
        value: '26',
        label: '26.皇宫'
      }, {
        value: '27',
        label: '27.宝物库'
      }, {
        value: '28',
        label: '28.交易所'
      }, {
        value: '29',
        label: '29.大兵营'
      }, {
        value: '30',
        label: '30.大马棚'
      }, {
        value: '35',
        label: '35.酿酒厂'
      }, {
        value: '36',
        label: '36.陷阱机'
      }, {
        value: '37',
        label: '37.英雄宅'
      }, {
        value: '38',
        label: '38.大仓库'
      }, {
        value: '39',
        label: '39.大谷仓'
      }, {
        value: '40',
        label: '40.世界奇观'
      }, {
        value: '41',
        label: '41.放牧水槽'
      }, {
        value: '44',
        label: '44.指挥中心'
      }, {
        value: '45',
        label: '45.水厂'
      }, {
        value: '46',
        label: '46.医院'
      }],
      dispatch: {
        password: '',
        attackTimerTasks: [],
        serverUrl: 'https://ts8.x1.asia.travian.com',
        useProxy: false,
        username: '',
        preparing: {
          troops: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          target: {},
          dispatchType: null,
          arrivalTime: null,
          catapult2: null,
          catapult1: null,
          villageId: null,
          useShip: false,
          currentSelectFeintTarget: null,
          feintMulti: null,
          feintTargets: [],
          feintTroops: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          feintTarget: {},
          feintArrivalTime: null,
          feintUseShip: false
        },
        tribeId: '0'
      }
    }
  }
}
</script>
<style scoped>
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
    padding-right: 30px;
  }
  .el-col > span {
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
</style>
