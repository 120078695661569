<template>
  <div>
    <el-radio-group v-model="radio" style="margin-bottom: 20px;" @change="handleRadioChange">
      <el-radio-button label="1 小时"></el-radio-button>
      <el-radio-button label="6 小时"></el-radio-button>
      <el-radio-button label="1 天"></el-radio-button>
    </el-radio-group>
    <div v-loading="loading">
      <div class='title'>
        <h3>{{datatype}}增长情况</h3>
        <p>数据每小时更新</p>
      </div>
      <v-chart :option="option" class="chart" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    datatype: {
      type: String,
      default: null
    },
    dataname: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      resdata: null,
      radio: '1 小时',
      loading: true,
      option: {
        grid: [
          { left: '5%', top: '5%', width: 1000, height: 600 }
        ],
        legend: {
          data: ['总' + this.datatype, this.datatype + '增量']
        },
        tooltip: {
          trigger: 'axis'
        },
        dataZoom: [{
          type: 'inside'
        }],
        xAxis: {
          type: 'time'
        },
        yAxis: [{
          name: '总' + this.datatype,
          type: 'value'
        },
        {
          name: this.datatype + '增量',
          type: 'value'
        }
        ],
        series: [{
          name: '总' + this.datatype,
          data: [],
          type: 'line'
        },
        {
          name: this.datatype + '增量',
          data: [],
          yAxisIndex: 1,
          type: 'bar'
        }
        ]
      }
    }
  },
  created () {
    this.$http.get('statisticlist').then(res => {
      this.loading = false
      var resdata = res.data.reverse()
      this.resdata = resdata
      this.loadChartData(resdata, 1)
    })
  },
  methods: {
    handleRadioChange (value) {
      var resdata = this.resdata
      if (value === '1 小时') this.loadChartData(resdata, 1)
      else if (value === '6 小时') this.loadChartData(resdata, 6)
      else if (value === '1 天') this.loadChartData(resdata, 24)
    },
    loadChartData (resdata, interval) {
      var option = this.option
      var lnum = null
      var maxnum = null
      var maxnum1 = null
      var minnum1 = null
      var seriesdata = [[], []]
      resdata.forEach(d => {
        var num = d[this.dataname]
        var time = new Date(d.time)
        if (time.getHours() % interval === 0) {
          if (lnum != null) {
            seriesdata[0].push([time, num])
            seriesdata[1].push([time, num - lnum > 0 ? num - lnum : 0])
            if (maxnum1 === null || maxnum1 < num - lnum) maxnum1 = num - lnum
            if (minnum1 === null || minnum1 > num - lnum) minnum1 = num - lnum
          }
          if (maxnum === null || maxnum < num) maxnum = num
          lnum = num
        }
      })
      option.series[0].data = seriesdata[0].slice(-32)
      option.series[1].data = seriesdata[1].slice(-32)
      const level = 5
      var sections = [10, 15, 20, 30, 50, 75,
        100, 150, 200, 300, 500, 750,
        1000, 1500, 2000, 3000, 5000, 7500,
        10000, 15000, 20000, 30000, 50000, 75000,
        100000, 150000, 200000, 300000, 500000, 750000
      ].reverse()
      sections.forEach(i => {
        if (i * level > maxnum) {
          option.yAxis[0].interval = i
          option.yAxis[0].max = i * level
        }
      })
      sections.forEach(i => {
        if (i * level > maxnum1) {
          option.yAxis[1].interval = i
          option.yAxis[1].max = i * level
        }
      })
      this.option = option
    }
  }
}
</script>

<style scoped>
  .chart {
    height: 680px;
    width: 100%;
  }

  .title>h3 {
    font-size: 24px;
    font-weight: 400;
    height: 30px;
    margin: 0;
  }

  .title>p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    color: #999999;
  }
</style>
