import Vue from 'vue'
import ECharts from 'vue-echarts'
import {
  use
} from 'echarts/core'
// 手动引入 ECharts 各模块来减小打包体积
import {
  ScatterChart,
  LineChart,
  BarChart
} from 'echarts/charts'
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  GridComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
  LegendComponent,
  MarkAreaComponent
} from 'echarts/components'

use([CanvasRenderer,
  ScatterChart,
  LineChart,
  BarChart,
  GridComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  DataZoomComponent,
  LegendComponent,
  MarkAreaComponent
])

// 全局注册组件（也可以使用局部注册）
Vue.component('v-chart', ECharts)
