<template>
  <div>
    <el-container>
      <el-header v-show="showNavi" style="padding:0;">
        <el-menu
        class="navi"
        :default-active="activeMenu"
        mode="horizontal"
        background-color="#545c64"
        text-color="#fff"
        :router="trueVar"
        active-text-color="#ffd04b">
          <el-menu-item index="shome"><i class="el-icon-s-order"></i>首页</el-menu-item>
          <el-menu-item index="tables"><i class="el-icon-s-data"></i>列表</el-menu-item>
          <el-menu-item index="statistics"><i class="el-icon-pie-chart"></i>统计</el-menu-item>
        </el-menu>
      </el-header>
      <el-main style="padding:10px 0;">
        <router-view :time="time" @setShowNavi="setShowNavi"/>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showNavi: true,
      trueVar: true,
      time: new Date().getTime()
    }
  },
  created () {
    setInterval(() => {
      this.time = new Date().getTime()
    }, 100)
  },
  computed: {
    activeMenu: {
      get () {
        return this.$store.state.activeMenu
      },
      set (value) {
        this.$store.commit('setActiveMenu', value)
      }
    }
  },
  methods: {
    setShowNavi (showNavi) {
      this.showNavi = showNavi
    }
  }
}
</script>
<style scoped>
.navi{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
</style>
