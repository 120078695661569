<template>
  <div>
    <el-card v-if="currentUser" style="margin-bottom: 20px;">
      <div slot="header" >
        <span style="font-size: 20px;">账号：{{currentUser.username}}</span>
      </div>
      <div class="villBasicInfo">
        <div class="lineInfo">
          <div>UID：{{currentUser.uid}} </div>
          <div>部落：{{currentUser.race}}</div>
          <div>金币 | 银币：{{currentUser.gold}} | {{currentUser.silver}}</div>
          <div>联盟：{{currentUser.alliance}}</div>
          <div>扩张({{currentUser.villageCount}}/{{currentUser.maxVillageCount}})：
            <div style="display: inline-block;">
              <el-progress style="width: 120px;" :show-text="false" :stroke-width="10" :percentage="currentUser.expansionPercentage" color='#ea9f02'></el-progress>
            </div>
          </div>
        </div>
        <el-collapse v-model="activeName">
          <el-collapse-item title="英雄" name="1">
            <div class="lineInfo">
              <div>驻在村：{{currentUser.hero.villagename}}</div>
              <div>状态：{{currentUser.hero.status}}</div>
              <div>冒险：{{currentUser.hero.adventurecount}}</div>
              <div>等级：{{currentUser.hero.level}}</div>
            </div>
            <div class="lineInfo">
              <div>战斗：{{currentUser.hero.attr1}}</div>
              <div>进攻：{{currentUser.hero.attr2}}</div>
              <div>防御：{{currentUser.hero.attr3}}</div>
              <div>资源：{{currentUser.hero.attr4}}</div>
            </div>
            <div class="lineInfo">
              <div>战斗力：{{currentUser.hero.power}}</div>
              <div>体力：
                <div style="display: inline-block;">
                  <el-progress style="width: 150px;" :text-inside="true" :stroke-width="17" :percentage="currentUser.hero.hp"></el-progress>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: '1'
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: null
    }
  }
}
</script>
<style scoped>
.villBasicInfo > div{
  padding: 10px;
  font-size: 13px;
}
.lineInfo{
  max-width: 1000px;
}
.lineInfo > div{
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.lineInfo > div:not(:last-child) {
  width: 28%;
}
</style>
