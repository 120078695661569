<template>
  <div>
    <el-card v-loading='loadingloglist'>
      <div slot="header" >
        <span style="font-size: 20px;">日志记录</span>
      </div>
      <el-table stripe :data='loglist' style='padding-left: 10px; width: 100%'>
        <el-table-column label='时间' width='200'>
          <template slot-scope='scope'>
            <i class='el-icon-time'></i>
            <span style='margin-left: 10px'>{{dateFormat("yyyy-MM-dd hh:mm:ss",scope.row.time)}}</span>
          </template>
        </el-table-column>
        <el-table-column label='村名' width='160'>
          <template slot-scope='scope'>{{scope.row.villname}}</template>
        </el-table-column>
        <el-table-column label='信息'>
          <template slot-scope='scope'>{{scope.row.info}}</template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    currentUser: {
      type: Object,
      default: null
    },
    activeTab: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loglist: [],
      loadingloglist: false,
      dateFormat: this.$format
    }
  },
  watch: {
    activeTab: function (val, oldval) {
      if (val === 'log') {
        this.loadingloglist = true
        this.$http.get('loglist/' + this.currentUser.uid + '?limit=100').then(res => {
          this.loglist = res.data
          this.loadingloglist = false
        })
      }
    }
  }
}
</script>
<style scoped>
</style>
