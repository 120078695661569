<template>
  <div>
    <el-container>
      <el-aside width="151px">
        <el-menu default-active="1"
          @select="handleSelect"
          style="width: 150px;">
          <el-menu-item index="1">
            <i class="el-icon-map-location"></i>
            <span>村庄分布</span>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-data-analysis"></i>
            <span>产量增长</span>
          </el-menu-item>
          <el-menu-item index="3">
            <i class="el-icon-data-line"></i>
            <span>人口增长</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <Map v-if="activeIndex==='1'" />
        <Production v-else-if="activeIndex==='2'" />
        <Population :datatype="pop" v-else-if="activeIndex==='3'" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Map from './statistics/Map.vue'
import Population from './statistics/Population.vue'
import Production from './statistics/Production.vue'
export default {
  components: {
    Map,
    Population,
    Production
  },
  data () {
    return {
      activeIndex: '1',
      pop: '人口',
      pro: '产量'
    }
  },
  methods: {
    handleSelect (index) {
      this.activeIndex = index
    }
  }
}
</script>
<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
