<template>
  <div>
    <el-card v-if="currentVillage" v-loading='loadingVillageDetails'>
      <div slot="header">
        <span style="font-size: 20px;">{{(currentVillage.iscapital?'主村：':'分村：')+currentVillage.villagename}}</span>
      </div>
      <div class="villBasicInfo">
        <div class="lineInfo">
          <div>NEWDID：{{currentVillage.newdid}}</div>
          <div>坐标：({{currentVillage.x}}|{{currentVillage.y}}) </div>
          <div>类型：{{currentVillage.type}} </div>
          <div>人口：{{currentVillage.inhabitants}}</div>
          <div>发展阶段：{{currentVillage.dtype}}</div>
          <div>总产量：{{currentVillage.r5p}}</div>
        </div>
        <div>
          <div class="storageBar">
            <i class="lumber_small"></i>
            <div>
              {{parseInt(currentVillage.r1s)}}/{{currentVillage.r1m}} <span style="color: orange">+{{currentVillage.r1p}}</span>
              <el-progress :show-text="false" :percentage="parseInt(currentVillage.r1s/currentVillage.r1m * 100)" color='#edc252'></el-progress>
            </div>
          </div>
          <div class="storageBar">
            <i class="clay_small"></i>
            <div>
              {{parseInt(currentVillage.r2s)}}/{{currentVillage.r2m}} <span style="color: orange">+{{currentVillage.r2p}}</span>
              <el-progress :show-text="false" :percentage="parseInt(currentVillage.r2s/currentVillage.r2m * 100)" color='#ab7d51'></el-progress>
            </div>
          </div>
          <div class="storageBar">
            <i class="iron_small"></i>
            <div>
              {{parseInt(currentVillage.r3s)}}/{{currentVillage.r3m}} <span style="color: orange">+{{currentVillage.r3p}}</span>
              <el-progress :show-text="false" :percentage="parseInt(currentVillage.r3s/currentVillage.r3m * 100)" color='#8c9db1'></el-progress>
            </div>
          </div>
          <div class="storageBar">
            <i class="crop_medium"></i>
            <div>
              {{parseInt(currentVillage.r4s)}}/{{currentVillage.r4m}} <span :style="currentVillage.r4p>0?'color: orange':'color: red'">{{currentVillage.r4p>0?'+':''}}{{currentVillage.r4p}}</span>
              <el-progress :show-text="false" :percentage="parseInt(currentVillage.r4s/currentVillage.r4m * 100)" color='#ea9f02'></el-progress>
            </div>
          </div>
        </div>
        <el-collapse v-model="activeName">
          <el-collapse-item v-if="currentVillage.traderlist.length > 0" title="商人" name="trader">
            <div v-for="(trader, index) in currentVillage.traderlist" :key="village.newdid+'_trader_'+index">
              <div class="trader">
                <div v-if="trader.newdid===currentVillage.newdid">前往 {{trader.aimusername}} 的村庄 {{trader.aimvillagename}}@({{trader.aimx}}|{{trader.aimy}})</div>
                <div v-else>来自{{trader.username}} 的村庄 {{trader.villagename}}@({{trader.x}}|{{trader.y}})</div>
                <div>
                  <div style="display: inline-block;">到达于 {{dateFormat(trader.duration)}} 后</div>
                  <div style="display: inline-block; float:right ">在{{dateFormat("hh:mm",trader.arrivaltime)}}</div>
                </div>
                <div class="resline">
                  <div>资源：</div>
                  <div><i class="r1"></i>{{trader.r1}}</div>
                  <div><i class="r2"></i>{{trader.r2}}</div>
                  <div><i class="r3"></i>{{trader.r3}}</div>
                  <div><i class="r4"></i>{{trader.r4}}</div>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="部队" name="troop">
            <div v-if="currentVillage.trainerlist.length > 0">
              <el-divider content-position="left">
                <span>训练队列</span>
              </el-divider>
              <table class="training" v-for="(trainer, index7) in currentVillage.trainerlist" :key="village.newdid+'_trainer_'+index7">
                <tr>
                  <td colspan="3"><img :class="'sbuilding sg'+trainer.gid" src="../../assets/imgs/x.gif" :title="trainer.buildingtype">
                    {{trainer.buildingtype}}</td>
                </tr>
                <tr>
                  <td>单位</td>
                  <td>时间</td>
                  <td>完成于</td>
                </tr>
                <tbody>
                  <tr v-for="(training, index8) in trainer.traininglist" :key="village.newdid+'_training_'+index8">
                    <td>
                      <img :class="'unit u'+training.uid" src="../../assets/imgs/x.gif" :title="training.unittype" />
                      {{training.num +' ' + training.unittype}}
                    </td>
                    <td>{{dateFormat(training.duration)}}</td>
                    <td>{{dateFormat("hh:mm",training.finishtime)}}</td>
                  </tr>
                  <tr class="next">
                    <td colspan="3">下一个单位将在 <span>{{dateFormat(trainer.next)}}</span> 后完成</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <el-divider content-position="left">
              <span>在本村的驻军</span>
            </el-divider>
            <el-card shadow="never" class="troopCard" body-style="padding: 0" v-for="(troop, index) in currentVillage.localtrooplist"
              :key="village.newdid+'_localtroop_'+index">
              <div slot="header">
                <span>{{troop.title}}</span>
              </div>
              <div id="troopInfo">
                <div v-for="(unit, index2) in troop.unitlist" :key="village.newdid+'_unit_'+index2"><img :class="'unit u'+unit.uid" :title="unit.type"
                    src="../../assets/imgs/x.gif">
                  <p>{{unit.number}}</p>
                </div>
              </div>
            </el-card>
            <el-divider content-position="left" v-if="currentVillage.foreigntrooplist.length > 0">
              <span>在其他村的驻军</span>
            </el-divider>
            <el-card shadow="never" class="troopCard" body-style="padding: 0" v-for="(troop, index5) in currentVillage.foreigntrooplist"
              :key="village.newdid+'_foreigntroop_'+index5">
              <div slot="header">
                <span>{{troop.title}}</span>
              </div>
              <div id="troopInfo">
                <div v-for="(unit, index4) in troop.unitlist" :key="village.newdid+'_unit2_'+index4"><img :class="'unit u'+unit.uid" :title="unit.type"
                    src="../../assets/imgs/x.gif">
                  <p>{{unit.number}}</p>
                </div>
              </div>
            </el-card>
          </el-collapse-item>
          <el-collapse-item title="建筑" name="building">
            <div v-if="currentVillage.buildingslotlist.length > 0">
              <el-divider content-position="left">
                <span>建造队列</span>
              </el-divider>
              <div class="buildingslot">
                <div v-for="(buildingslot, index3) in currentVillage.buildingslotlist" :key="village.newdid+'_buildingslot_'+index3">
                  <div>
                    <img :class="'sbuilding sg'+buildingslot.gid" src="../../assets/imgs/x.gif" :title="buildingslot.type">
                    <div>{{buildingslot.type}} </div>
                    <div style="color: #00BC00;">等级 {{buildingslot.level}}</div>
                    <div>{{dateFormat(buildingslot.duration)}} 后，完成于
                      {{dateFormat("hh:mm",buildingslot.finishtime)}}</div>
                  </div>
                </div>
              </div>
            </div>
            <el-divider content-position="left">
              <span>外村</span>
            </el-divider>
            <div class="buildingCard" v-for="(building) in currentVillage.outterBuildinglist" :key="village.newdid+'_building_'+building.areaid">
              <el-card shadow="never" body-style="padding:15px">
                <div slot="header" :style="'text-align: center;' + (building.upgrading ? 'color: #FFA84F;' : '')">
                  <span>{{building.type}} lv.{{building.level}}</span>
                </div>
                <div>
                  <img :class="'building g'+building.gid" src="../../assets/imgs/x.gif" :title="building.type">
                </div>
              </el-card>
            </div>
            <el-divider content-position="left">
              <span>内村</span>
            </el-divider>
            <div class="buildingCard" v-for="(building) in currentVillage.innerBuildinglist" :key="village.newdid+'_building_'+building.areaid">
              <el-card shadow="never" body-style="padding:15px;">
                <div slot="header" :style="'text-align: center;' + (building.upgrading ? 'color: #FFA84F;' : '')">
                  <span>{{building.type}} lv.{{building.level}}</span>
                </div>
                <div>
                  <img :class="'building g'+building.gid+' '+currentVillage.tribe" src="../../assets/imgs/x.gif" :title="building.type">
                </div>
              </el-card>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      default: null
    },
    village: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      next: null,
      currentVillage: null,
      loadingVillageDetails: false,
      activeName: ['trader', 'troop', 'building'],
      dateFormat: this.$format
    }
  },
  methods: {
    loadVill (vill) {
      vill.innerBuildinglist = []
      vill.outterBuildinglist = []
      // 预处理建筑
      vill.buildinglist.forEach(building => {
        if (building.areaid < 19) vill.outterBuildinglist.push(building)
        else if (building.gid > 0) vill.innerBuildinglist.push(building)
        building.upgrading = false
        vill.buildingslotlist.forEach(buildingslot => {
          buildingslot.duration = 0
          if (!buildingslot.building && building.gid === buildingslot.gid && building.level ===
              buildingslot.level - 1) {
            buildingslot.building = building
            building.upgrading = true
          }
        })
      })
      // 预处理商队
      vill.traderlist.forEach(t => {
        t.duration = 0
      })
      var trainerlist = []
      vill.traininglist.forEach(t => {
        t.duration = 0
        t.finishtime = t.starttime + t.num * t.persecs * 1000
        var flag = true
        trainerlist.forEach(tr => {
          if (tr.gid === t.gid) {
            flag = false
            tr.traininglist.push(t)
          }
        })
        if (flag) {
          trainerlist.push({
            buildingtype: t.buildingtype,
            gid: t.gid,
            traininglist: [t],
            next: 10000
          })
        }
      })
      vill.trainerlist = trainerlist
      // 预处理训练队列
      vill.traininglist.forEach(t => {
        t.duration = 0
        t.finishtime = t.starttime + t.num * t.persecs * 1000
      })
      if (vill.race === '罗马人') {
        vill.raceid = 0
        vill.tribe = 'roman'
      } else if (vill.race === '日耳曼人') {
        vill.raceid = 1
        vill.tribe = 'teuton'
      } else if (vill.race === '高卢人') {
        vill.raceid = 2
        vill.tribe = 'gaul'
      } else if (vill.race === '埃及人') {
        vill.raceid = 5
        vill.tribe = 'egyptian'
      } else if (vill.race === '匈奴人') {
        vill.raceid = 6
        vill.tribe = 'hun'
      } else if (vill.race === '斯巴达人') {
        vill.raceid = 7
        vill.tribe = 'spartan'
      }
      var trooplist = [{
        raceid: vill.raceid,
        title: '本村部队',
        fromnewdid: vill.newdid,
        newdid: vill.newdid,
        unitlist: []
      }]
      // 第一步预处理找出部队编制
      vill.unitlist.forEach(u => {
        if (u.tid === 11) u.uid = 'hero'
        else u.uid = u.tid + u.raceid * 10
        var flag = true
        trooplist.forEach(t => {
          if (u.fromnewdid === t.fromnewdid && u.newdid === t.newdid) flag = false
        })
        if (flag) {
          var title
          if (u.newdid === vill.newdid) {
            title = u.fromusername + '的部队, 来自' + u.fromvillname + ' @(' + u.fromx + '|' + u.fromy + ')'
          } else title = ' 位于 ' + u.username + ' 的村庄 ' + u.villname + ' @(' + u.x + '|' + u.y + ')'
          trooplist.push({
            raceid: u.raceid,
            title: title,
            fromnewdid: u.fromnewdid,
            newdid: u.newdid,
            unitlist: []
          })
        }
      })
      // 第二步填入编制
      trooplist.forEach(t => {
        for (var i = 1; i < 12; i++) {
          t.unitlist.push({
            uid: i < 11 ? ((i + t.raceid * 10) + '') : 'hero',
            type: '',
            number: 0
          })
        }
        vill.unitlist.forEach(u => {
          if (u.fromnewdid === t.fromnewdid && u.newdid === t.newdid) {
            t.unitlist[u.tid - 1].type = u.type
            t.unitlist[u.tid - 1].number += u.number
          }
        })
      })
      vill.localtrooplist = []
      vill.foreigntrooplist = []
      trooplist.forEach(e => {
        if (e.newdid === vill.newdid) vill.localtrooplist.push(e)
        else vill.foreigntrooplist.push(e)
      })
      this.currentVillage = vill
    }
  },
  created () {
    this.loadVill(this.village)
  },
  watch: {
    village: function (val, oldval) {
      this.loadVill(val)
    },
    time: function (val, oldval) {
      var vill = this.currentVillage
      if (vill != null) {
        // 增加资源
        vill.r1s += vill.r1p * (val - oldval) / 3600000
        vill.r2s += vill.r2p * (val - oldval) / 3600000
        vill.r3s += vill.r3p * (val - oldval) / 3600000
        vill.r4s += vill.r4p * (val - oldval) / 3600000

        // 商人队列
        for (var i = 0; i < vill.traderlist.length; i++) {
          var trader = vill.traderlist[i]
          trader.duration = trader.arrivaltime - val
          if (trader.arrivaltime < val) {
            vill.traderlist.splice(i, 1)
            if (trader.aimnewdid === vill.newdid) {
              vill.r1s += trader.r1
              vill.r2s += trader.r2
              vill.r3s += trader.r3
              vill.r4s += trader.r4
            }
          }
        }

        // 容量限制
        if (vill.r1s > vill.r1m) vill.r1s = vill.r1m
        if (vill.r2s > vill.r2m) vill.r2s = vill.r2m
        if (vill.r3s > vill.r3m) vill.r3s = vill.r3m
        if (vill.r4s > vill.r4m) vill.r4s = vill.r4m
        if (vill.r4s < 0) vill.r4s = 0
        for (var j = 0; j < vill.buildingslotlist.length; j++) {
          var buildingslot = vill.buildingslotlist[j]
          buildingslot.duration = buildingslot.finishtime - val
          if (buildingslot.finishtime < val) {
            vill.buildingslotlist.splice(j, 1)
            buildingslot.building.level++
            buildingslot.building.upgrading = false
          }
        }

        // 训练队列
        for (var n = 0; n < vill.trainerlist.length; n++) {
          var next = 10000000
          var trainer = vill.trainerlist[n]
          for (var k = 0; k < trainer.traininglist.length; k++) {
            var training = trainer.traininglist[k]
            training.duration = training.finishtime - val
            if (val > training.starttime + training.persecs * 1000) {
              var num = parseInt((val - training.starttime) / training.persecs / 1000)
              training.num -= num
              training.starttime += training.persecs * 1000 * num
              if (training.num < 1) trainer.traininglist.splice(k, 1)
              vill.localtrooplist.forEach(t => {
                if (t.fromnewdid === vill.newdid) {
                  t.unitlist.forEach(u => {
                    if (u.uid === training.uid + '') u.number += num
                  })
                }
              })
            }
            if (val > training.starttime) {
              var nexta = training.starttime + training.persecs * 1000 - val
              if (next === null || next > nexta) next = nexta
            }
          }
          if (trainer.traininglist.length < 1) vill.trainerlist.splice(n, 1)
          trainer.next = next
        }
        this.currentVillage = vill
      }
    }
  }
}
</script>
<style scoped>
  .storageBar {
    display: inline-block;
    width: 23%;
    min-width: 160px;
    max-width: 255px;
    padding: 5px;
  }

  .storageBar>div {
    font-size: 13px;
    display: inline-block;
    width: 80%;
  }

  .buildingCard {
    width: 152px;
    display: inline-block;
    margin: 10px;
    font-size: 15px;
  }

  .troops th {
    text-align: left;
  }

  .villBasicInfo>div {
    padding: 10px;
    font-size: 13px;
  }

  #troopInfo div {
    display: inline-block;
    width: 55px;
    padding-top: 10px;
    text-align: center;
  }

  #troopInfo div:nth-of-type(n+2) {
    border-left: 1px solid #e7e7e7;
  }

  #troopInfo p {
    height: 25px;
    padding-top: 10px;
    border-top: 1px solid #e7e7e7;
  }

  .troopCard {
    width: 615px;
    margin: 10px;
  }

  .lineInfo {
    max-width: 1000px;
  }

  .lineInfo>div {
    display: inline-block;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .lineInfo>div:not(:last-child) {
    width: 28%;
  }

  .trader {
    border: 1px solid #e7e7e7;
    margin: 10px;
    max-width: 500px;
  }

  .trader>div {
    padding: 10px;
  }

  .trader>div:nth-of-type(n+2) {
    border-top: 1px solid #e7e7e7;
  }

  .resline>div {
    display: inline-block;
  }

  .resline>div:nth-of-type(n+2) {
    width: 20%
  }

  .buildingslot {
    width: 340px;
    margin-left: 10px;
    padding: 10px 20px;
    border-radius: 5px;
  }

  .buildingslot>div>div>div {
    display: inline-block;
    min-width: 60px;
  }

  .buildingslot>div>div>div:last-child {
    float: right;
  }

  .training {
    border-collapse: collapse;
    margin: 10px;
  }

  .training td {
    width: 151px;
    border: 1px solid #e7e7e7;
    margin: 0;
    text-align: center;
  }

  .next {
    background-color: #e7e7e7;
  }
</style>
