<template>
  <Growth :datatype="datatype" :dataname="dataname" />
</template>

<script>
import Growth from './Growth.vue'
export default {
  components: {
    Growth
  },
  data () {
    return {
      datatype: '产量',
      dataname: 'basicProduction'
    }
  }
}
</script>
