import Vue from 'vue'
import VueRouter from 'vue-router'
import Sendtroop from '../components/Sendtroop.vue'
import Home from '../components/Home.vue'
import Tables from '../components/Tables.vue'
import Statistics from '../components/Statistics.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/sendtroop'
},
{
  path: '*',
  redirect: '/sendtroop'
},
{
  path: '/sendtroop',
  name: 'Sendtroop',
  component: Sendtroop,
  meta: {
    title: '发兵编辑器'
  }
},
{
  path: '/shome',
  name: 'Home',
  component: Home,
  meta: {
    title: 'ASIAX2 - 首页'
  }
},
{
  path: '/tables',
  name: 'Tables',
  component: Tables,
  meta: {
    title: 'ASIAX2 - 列表'
  }
},
{
  path: '/statistics',
  name: 'Statistics',
  component: Statistics,
  meta: {
    title: 'ASIAX2 - 统计'
  }
}
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  store.commit('setActiveMenu', to.path.substr(1))
  document.title = to.meta.title
  return next()
})

export default router
