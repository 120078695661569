<template>
  <div>
    <el-container>
      <el-aside width="151px">
        <el-menu default-active="1" @select="handleSelect" style="width: 150px;">
          <el-menu-item index="1">
            <i class="el-icon-user"></i>
            <span>账号列表</span>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-s-shop"></i>
            <span>村庄列表</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <UserTable v-if="activeIndex==='1'" />
        <VillageTable v-else-if="activeIndex==='2'" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import UserTable from './tables/UserTable.vue'
import VillageTable from './tables/VillageTable.vue'
export default {
  components: {
    UserTable,
    VillageTable
  },
  data () {
    return {
      activeIndex: '1'
    }
  },
  methods: {
    handleSelect (index) {
      this.activeIndex = index
    }
  }
}
</script>
<style scoped>
</style>
