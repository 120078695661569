<template>
  <div>
    <el-radio-group v-model="radio">
      <el-radio-button label="基础"></el-radio-button>
      <el-radio-button label="资源"></el-radio-button>
      <el-radio-button label="部队"></el-radio-button>
    </el-radio-group>
    <el-button style="float: right" @click="clearFilter">清除过滤器</el-button>
    <el-table :data="tableData" style="width: 100%; margin-top: 20px;" v-loading="loading" ref="filterTable">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="userinfo">
            <div>
              <div>村庄编号</div><span>{{ props.row.newdid }}</span>
            </div>
            <div v-if="radio!=='基础'">
              <div>人口 | 阶段</div>
              <span>{{ props.row.inhabitants }} | {{ props.row.dtype }}</span>
            </div>
            <div v-if="radio!=='基础'">
              <div>种族 | 类型</div>
              <span><i :class="'tribe_small_' + props.row.raceid"></i>{{ props.row.race }} | {{ props.row.type }}</span>
            </div>
            <div>
              <div>资源产量</div>
              <span><i class="r1"></i>{{ props.row.r1p }}</span>
              <span><i class="r2"></i>{{ props.row.r2p }}</span>
              <span><i class="r3"></i>{{ props.row.r3p }}</span>
              <span><i class="r4"></i>{{ props.row.r4p }}</span>
            </div>
            <div v-if="radio!=='资源'">
              <div>仓库</div>
              <span><i class="r1"></i>{{ props.row.r1s }}/ {{props.row.r1m}}
                <el-progress style="width: 130px;" :show-text="false" color="#edc252" :percentage="parseInt(props.row.r1s/props.row.r1m*100)"></el-progress>
              </span>
              <span><i class="r2"></i>{{ props.row.r2s }}/ {{props.row.r2m}}
                <el-progress style="width: 130px;" :show-text="false" color="#ab7d51" :percentage="parseInt(props.row.r2s/props.row.r2m*100)"></el-progress>
              </span>
              <span><i class="r3"></i>{{ props.row.r3s }}/ {{props.row.r3m}}
                <el-progress style="width: 130px;" :show-text="false" color="#8c9db1" :percentage="parseInt(props.row.r3s/props.row.r3m*100)"></el-progress>
              </span>
              <span><i class="r4"></i>{{ props.row.r4s }}/ {{props.row.r4m}}
                <el-progress style="width: 130px;" :show-text="false" color="#ea9f02" :percentage="parseInt(props.row.r4s/props.row.r4m*100)"></el-progress>
              </span>
            </div>
            <div v-if="props.row.localUnitlist.length > 0">
              <div>村内部队</div>
              <span v-for="(unit, index) in props.row.localUnitlist" :key="index" style="width: 80px;">
                <img :class="'unit u'+unit.uid" src='../../assets/imgs/x.gif' :title="unit.type">
                {{unit.number}}
              </span>
            </div>
            <div v-if="props.row.availableUnitlist.length > 0 && radio=='部队'">
              <div>所属部队</div>
              <span v-for="(unit, index) in props.row.belongingUnitlist" :key="index" style="width: 80px;">
                <img :class="'unit u'+unit.uid" src='../../assets/imgs/x.gif' :title="unit.type">
                {{unit.number}}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="newdid" sortable label="村名">
        <template slot-scope="scope">
          {{scope.row.villagename}}
        </template>
      </el-table-column>
      <el-table-column prop="uid" sortable label="用户" :filters="usernameOptions" :filter-method="filterHandler" column-key="uid">
        <template slot-scope="scope">
          {{scope.row.username}}
        </template>
      </el-table-column>
      <el-table-column prop="type" sortable  label="类型" :filters="villageTypeOptions" :filter-method="filterHandler" column-key="type">
      </el-table-column>
      <el-table-column label="基础" v-if="radio==='基础'" align="center">
        <el-table-column label="坐标">
          <template slot-scope="scope">
            {{scope.row.x}}|{{scope.row.y}}
          </template>
        </el-table-column>
        <el-table-column prop="raceid" sortable label="种族" min-width="109">
          <template slot-scope="scope">
            <i :class="'tribe_' + scope.row.raceid"></i>
            {{scope.row.race}}
          </template>
        </el-table-column>
        <el-table-column prop="dtype" sortable label="阶段">
        </el-table-column>
        <el-table-column prop="inhabitants" sortable label="人口">
        </el-table-column>
        <el-table-column prop="r5p" sortable label="产量">
        </el-table-column>
      </el-table-column>
      <el-table-column label="资源" v-else-if="radio==='资源'" align="center">
        <el-table-column prop="r1s" sortable min-width="100" class="resinfo">
          <template slot="header">
            <i class="lumber_small"></i>
            <span> 木材</span>
          </template>
          <template slot-scope="scope">
            <div>
              <div class="resline"><div>{{scope.row.r1s}}/{{scope.row.r1m}}</div><div style="color: orange">+{{scope.row.r1p}}</div></div>
              <el-progress :show-text="false" color="#edc252" :percentage="parseInt(scope.row.r1s/scope.row.r1m*100)"></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="r2s" sortable min-width="100">
          <template slot="header">
            <i class="clay_small"></i>
            <span> 泥土</span>
          </template>
          <template slot-scope="scope">
            <div>
              <div class="resline"><div>{{scope.row.r2s}}/{{scope.row.r2m}}</div><div style="color: orange">+{{scope.row.r2p}}</div></div>
              <el-progress :show-text="false" color="#ab7d51" :percentage="parseInt(scope.row.r2s/scope.row.r2m*100)"></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="r3s" sortable min-width="100">
          <template slot="header">
            <i class="iron_small"></i>
            <span> 钢铁</span>
          </template>
          <template slot-scope="scope">
            <div>
              <div class="resline"><div>{{scope.row.r3s}}/{{scope.row.r3m}}</div><div style="color: orange">+{{scope.row.r3p}}</div></div>
              <el-progress :show-text="false" color="#8c9db1" :percentage="parseInt(scope.row.r3s/scope.row.r3m*100)"></el-progress>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sd" sortable label="标准差">
        </el-table-column>
        <el-table-column prop="r4s" sortable min-width="100">
          <template slot="header">
            <i class="crop_medium"></i>
            <span> 谷物</span>
          </template>
          <template slot-scope="scope">
            <div>
              <div class="resline">
                <div>{{scope.row.r4s}}/{{scope.row.r4m}}</div>
                <span :style="scope.row.r4p>0?'color: orange':'color: red'">{{scope.row.r4p>0?'+':''}}{{scope.row.r4p}}</span>
                </div>
              <el-progress :show-text="false" color="#ea9f02" :percentage="parseInt(scope.row.r4s/scope.row.r4m*100)"></el-progress>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="可用部队" v-else-if="radio==='部队'" align="center">
        <el-table-column  v-for="index of 11" :key="index" :prop="'t'+index" :label="index+''" min-width="50" sortable>
          <template slot-scope="scope">
            <img :class="'unit u'+(index<11?(index+(scope.row.raceid)*10):'hero')" src="../../assets/imgs/x.gif">
            {{scope.row['t'+index]}}
          </template>
        </el-table-column>
        <el-table-column prop="upkeep" sortable label="米数">
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '基础',
      loading: true,
      tableData: [],
      usernameOptions: [],
      villageTypeOptions: []
    }
  },
  created () {
    this.$http.get('villagelist').then(res => {
      var villagelist = res.data
      villagelist.forEach(v => {
        var username = this.usernameOptions.find(item => {
          return item.value === v.uid
        })
        if (!username) this.usernameOptions.push({ text: v.username, value: v.uid })
        var villageType = this.villageTypeOptions.find(item => {
          return item.value === v.type
        })
        if (!villageType) this.villageTypeOptions.push({ text: v.type, value: v.type })
        for (var i = 1; i < 12; i++) {
          v['t' + i] = 0
        }
        v.localUnitlist = []
        v.belongingUnitlist = []
        v.availableUnitlist = []
        v.unitlist.forEach(u => {
          if (u.tid === 11) u.uid = 'hero'
          else u.uid = u.tid + u.raceid * 10
          if (u.newdid === v.newdid) v.localUnitlist.push(u)
          if (u.fromnewdid === v.newdid) v.belongingUnitlist.push(u)
          if (u.fromnewdid === v.newdid && u.newdid === v.newdid) v.availableUnitlist.push(u)
        })
        v.availableUnitlist.forEach(u => {
          v['t' + u.tid] += u.number
        })
        // 重排
        this.$mergeUnits(v.belongingUnitlist)
        this.$mergeUnits(v.localUnitlist)
        if (!v.upkeep) v.upkeep = 0
        var avg = (v.r1s + v.r2s + v.r3s) / 3
        v.sd = parseInt(Math.sqrt(((v.r1s - avg) * (v.r1s - avg) + (v.r2s - avg) * (v.r2s - avg) + (v.r3s - avg) * (v.r3s - avg)) / 2))
      })
      this.tableData = res.data
      this.loading = false
    })
  },
  methods: {
    filterHandler (value, row, column) {
      const property = column.property
      return row[property] === value
    },
    clearFilter () {
      this.$refs.filterTable.clearFilter()
    }
  }
}
</script>

<style scoped>
  .userinfo>div {
    padding-bottom: 10px;
    padding-left: 50px;
  }

  .userinfo>div>div {
    display: inline-block;
    color: gray;
    width: 80px;
  }

  .resline>div {
    display: inline-block;
  }

  .userinfo>div>span {
    display: inline-block;
    width: 155px;
  }
</style>
