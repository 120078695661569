<template>
  <div v-loading="loading">
    <div class='title'>
      <h3>{{mapCenter}} 区域村庄分布</h3>
      <p>数据实时更新(总数：{{villagecount}})</p>
      <el-radio-group v-model="mapCenter" size="medium" @change="handleRadioChange">
        <el-radio-button label="出生区" ></el-radio-button>
        <el-radio-button label="集村区"></el-radio-button>
      </el-radio-group>
    </div>
    <v-chart :option="option" class="chart" :style="mapStyle"/>
  </div>
</template>

<script>
var villinfos
export default {
  data () {
    return {
      villagecount: null,
      loading: true,
      mapStyle: {
        width: 'auto',
        height: 'auto'
      },
      mapCenter: '集村区',
      option: {
        grid: [
          { left: '5%', top: '5%', width: 800, height: 600 }
        ],
        tooltip: {
          showDelay: 0,
          formatter: function (params) {
            const x = params.value[0]
            const y = params.value[1]
            var vill = null
            villinfos.forEach(v => {
              if (v.x === x && v.y === y) vill = v
            })
            return '<div style="font-size: 20px;padding:2px">村庄 ' + vill.villagename + '</div>' +
                '(' + x + '| ' + y + ')<br/>' +
                '玩家 ' + vill.username + '<br/>' +
                '人口 ' + vill.inhabitants + '<br/>' +
                '种族 ' + vill.race + '<br/>' + (vill.unitinfo !== ''
              ? '部队 ' + vill.unitinfo : '')
          },
          axisPointer: {
            show: true,
            type: 'cross',
            lineStyle: {
              type: 'dashed',
              width: 1
            }
          }
        },
        dataZoom: [{
          type: 'inside'
        }, {
          type: 'inside',
          orient: 'vertical'
        }],
        xAxis: {
          name: 'X',
          splitNumber: 6,
          max: 80,
          min: 0
        },
        yAxis: {
          name: 'Y',
          splitNumber: 4,
          max: -10,
          min: -70
        },
        series: [{
          name: 'aoligei',
          symbolSize: 4,
          data: [],
          type: 'scatter',
          markArea: {
            silent: true,
            itemStyle: {
              color: 'transparent',
              borderWidth: 1,
              borderType: 'dashed'
            },
            data: [
              [{
                name: '出生区',
                xAxis: 0,
                yAxis: 0
              }, {
                xAxis: 100,
                yAxis: -90
              }],
              [{
                name: '集村区',
                xAxis: 100,
                yAxis: -50
              }, {
                xAxis: 140,
                yAxis: -10
              }]
            ]
          }
        }]
      }
    }
  },
  created () {
    var option = this.option
    var mapStyle = this.mapStyle
    var height = Math.min(window.innerHeight - 250, window.innerWidth - 200)
    mapStyle.height = height + 'px'
    mapStyle.width = height * 1.64 + 'px'
    this.mapStyle = mapStyle
    this.$http.get('uservillageunitlist').then(res => {
      var userlist = res.data
      var villlist = []
      userlist.forEach(u => {
        var serie = {
          name: u.username,
          symbol: 'rect',
          symbolSize: 7,
          data: [],
          type: 'scatter',
          emphasis: {
            focus: 'series'
          }
        }
        u.villageList.forEach(v => {
          // 预处理部队信息
          // 加入 uid
          v.unitlist.forEach(u => {
            if (u.tid === 11) u.uid = 0
            else u.uid = u.tid + u.raceid * 10
          })
          // 重排
          v.unitlist2 = []
          for (var i = 0; i < 71; i++) {
            v.unitlist2[i] = 0
            v.unitlist.forEach(u => {
              if (u.uid === i) {
                v.unitlist2[i] += u.number
              }
            })
          }
          // 产生字符串
          v.unitinfo = ''
          for (i = 0; i < 71; i++) {
            var uid = i
            var number = v.unitlist2[i]
            if (v.unitlist2[i] > 0) {
              if (uid === 0) uid = 'hero'
              v.unitinfo += '<img src="data:image/gif;base64,R0lGODlhAQABAJEAAAAAAP///////wAAACH5BAUUAAIALAAAAAABAAEAAAICVAEAOw==" class="unit u' + uid + '">' + number + ' '
            }
          }
          villlist.push(v)
          serie.data.push([v.x, v.y])
        })
        option.series.push(serie)
      })
      villinfos = villlist
      this.villagecount = villinfos.length
      this.option = option
      this.loading = false
    })
    this.handleRadioChange(this.mapCenter)
  },
  methods: {
    handleRadioChange (value) {
      if (value === '出生区') this.moveMapCenterTo(50, -45)
      else if (value === '集村区') this.moveMapCenterTo(125, -30)
    },
    moveMapCenterTo (x, y) {
      var xmax = x + 60
      var xmin = x - 60
      var ymax = y + 45
      var ymin = y - 45
      var option = this.option
      option.xAxis.max = xmax
      option.xAxis.min = xmin
      option.xAxis.splitNumber = 6
      option.yAxis.max = ymax
      option.yAxis.min = ymin
      option.yAxis.splitNumber = 4
      this.option = option
    }
  }
}
</script>

<style scoped>
  .title>h3 {
    font-size: 24px;
    font-weight: 400;
    height: 30px;
    margin: 0;
  }

  .title>p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    color: #999999;
  }
</style>
