<template>
  <el-table :data="tableData" style="width: 100%" v-loading="loading">
    <el-table-column type="expand">
      <template slot-scope="props">
        <div class="userinfo">
          <div><div>用户编号</div><span>{{ props.row.uid }}</span></div>
          <div><div>联盟名称</div><span>{{ props.row.alliance }}</span></div>
          <div><div>Mainvill</div><span>{{ props.row.x }}|{{ props.row.y }}</span></div>
          <div><div>最大村数</div><span>{{ props.row.maxVillageCount }}</span></div>
          <div><div>金币 | 银币</div><span>{{props.row.gold}} | {{props.row.silver}}</span></div>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="uid" sortable label="账户名" min-width="120px">
      <template slot-scope="scope">
        {{scope.row.username}}
      </template>
    </el-table-column>
    <el-table-column label="种族" prop="raceid" sortable>
      <template slot-scope="scope">
        <i :class="'tribe_' + scope.row.raceid"></i>
        {{scope.row.race}}
      </template>
    </el-table-column>
    <el-table-column prop="villageCount" sortable label="村数">
    </el-table-column>
    <el-table-column prop="hero.level" sortable label="等级">
    </el-table-column>
    <el-table-column label="文明点" prop="expansionPercentage" sortable>
      <template slot-scope="scope">
        <el-progress style="width: 80%;" :show-text="false" :stroke-width="10" :percentage="scope.row.expansionPercentage" color='#ea9f02'></el-progress>
      </template>
    </el-table-column>
    <el-table-column prop="production" sortable label="总产量">
    </el-table-column>
    <el-table-column prop="inhabitants" sortable label="总人口">
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      tableData: []
    }
  },
  created () {
    this.$http.get('userlist').then(res => {
      this.tableData = res.data
      this.loading = false
    })
  }
}
</script>

<style scoped>
  .userinfo > div{
    padding-left: 25px;
    padding-bottom: 10px;
  }
  .userinfo > div > div{
    display: inline-block;
    color: gray;
    width: 80px;
  }
</style>
