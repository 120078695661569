<template>
  <div>
    <el-container>
      <el-aside width="151px">
        <el-menu
          :style="'width: 150px;height: ' + menuHeight + 'px;'"
          v-loading='loadinguserlist'
          @select="handleSelect">
          <el-menu-item :index="user.uid.toString()" v-for="(user, index) in userlist" :key="index">
            <template slot="title">
              <i :class="'tribe_small_' + user.raceid"></i>
              <span> {{user.username}}</span>
            </template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
          <el-tabs v-model="activeTab" v-if="currentUser">
            <el-tab-pane label="账号信息" name="info">
              <UserInfo :currentUser="currentUser" ></UserInfo>
            </el-tab-pane>
            <el-tab-pane label="日志记录" name="log">
              <LogInfo :currentUser="currentUser" :activeTab="activeTab"></LogInfo>
            </el-tab-pane>
            <el-tab-pane :label="village.type + ': ' + village.villagename" :name="village.newdid+''" v-for="(village, index) in villagelist" :key="index">
              <VillageInfo :village="village" :time="time" :newdid="village.newdid+''" :currentNewdid="activeTab"></VillageInfo>
            </el-tab-pane>
          </el-tabs>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import UserInfo from './home/UserInfo.vue'
import LogInfo from './home/LogInfo.vue'
import VillageInfo from './home/VillageInfo.vue'

export default {
  components: {
    UserInfo,
    VillageInfo,
    LogInfo
  },
  props: {
    time: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      menuHeight: 500,
      loadinguserlist: false,
      currentUser: null,
      activeTab: null,
      userlist: [],
      villagelist: []
    }
  },
  created () {
    this.loadinguserlist = true
    this.$http.get('uservillagelist').then(res => {
      this.userlist = res.data
      this.loadinguserlist = false
      this.menuHeight = res.data.length * 58
    })
  },
  methods: {
    handleSelect (index, indexPath) {
      this.index = index
      this.userlist.forEach(u => {
        if (u.uid + '' === indexPath[0]) {
          this.currentUser = u
          this.villagelist = u.villageList
          this.activeTab = 'info'
        }
      })
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
</style>
