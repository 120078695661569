<template>
  <div id="app">
    <Navi/>
  </div>
</template>

<script>
import Navi from './components/Navi.vue'

export default {
  components: {
    Navi
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'margin: 0;')
  }
}
</script>

<style>
@import url("./assets/timg.css");
@media screen and (max-width:1024px) {
  #app{
    width: 1024px;
  }
}
</style>
