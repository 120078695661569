import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import store from './store'
import '@/plugins/echarts'
import '@/plugins/element'

Vue.config.productionTip = false
axios.defaults.baseURL = 'http://124.220.34.11:8080/mytravian_api/'
// axios.defaults.baseURL = 'http://localhost:8080/mytravian_api/'

Vue.prototype.$http = axios

Vue.prototype.$format = function (fmt, millisecs) {
  if (millisecs == null) {
    var secs = parseInt(fmt / 1000)
    var hours = parseInt(secs / 3600)
    var mins = parseInt(secs % 3600 / 60)
    secs = secs % 60
    if (mins < 10) mins = '0' + mins
    if (secs < 10) secs = '0' + secs
    var str = hours + ':' + mins + ':' + secs
    return str
  }
  var date = new Date(millisecs)
  let ret
  const opt = {
    'y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月
    'd+': date.getDate().toString(), // 日
    'h+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
    }
  }
  return fmt
}

Vue.prototype.$mergeUnits = function (units) {
  var units2 = []
  for (var j = 0; j < 71; j++) {
    units.forEach(u => {
      if (u.uid === j) {
        if (units2[j] == null) units2[j] = { uid: u.uid, number: u.number }
        else units2[j].number += u.number
      }
    })
  }
  units.splice(0, units.length)
  units2.forEach(u => {
    if (u != null) units.push(u)
  })
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
